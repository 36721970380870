export function getZoneDate(dateString: string | Date) {
   const dateObj = new Date(dateString);
   return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000);
}

type IFormattedDateFormats =
   | 'dddd, mmmm ddS'
   | 'mm/dd/yyyy'
   | 'mmmm dd, yyyy at hh:mm AM/PM';
interface IFormattedDateOptionParams {
   format?: IFormattedDateFormats;
   useZoneOffset?: boolean;
}
export function getFormattedDate(
   date: string | Date,
   format?: IFormattedDateFormats
): string;
export function getFormattedDate(
   date: string | Date,
   options?: IFormattedDateOptionParams
): string;
export function getFormattedDate(
   date: string | Date,
   arg2?: IFormattedDateFormats | IFormattedDateOptionParams
) {
   let format: IFormattedDateFormats | undefined;
   let options: IFormattedDateOptionParams | undefined = {};

   if (typeof arg2 === 'string') {
      format = arg2;
   } else if (typeof arg2 === 'object') {
      options = arg2;
      if (options.format) format = options.format;
   }

   const { useZoneOffset = true } = options || {};

   const dateObj = useZoneOffset ? getZoneDate(date) : new Date(date);
   // const months = [
   //    'January',
   //    'February',
   //    'March',
   //    'April',
   //    'May',
   //    'June',
   //    'July',
   //    'August',
   //    'September',
   //    'October',
   //    'November',
   //    'December',
   // ];
   switch (format) {
      case 'dddd, mmmm ddS':
         return (
            dateObj.toLocaleString('en-US', {
               weekday: 'long',
               month: 'long',
               day: 'numeric',
            }) + getNthDay(dateObj.getDate())
         );
      case 'mm/dd/yyyy':
         return dateObj.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
         });
      case 'mmmm dd, yyyy at hh:mm AM/PM':
         return dateObj.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
         });

      default:
         return dateObj.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
         });

      // default:
      //    return `${
      //       months[dateObj.getMonth()]
      //    } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
   }
}

export function calcAge(dob: string | Date) {
   if (!dob) return '';
   var diff_ms = Date.now() - getZoneDate(dob).getTime();
   var age_dt = new Date(diff_ms);

   return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export function dateObjToInputVal(
   dateString?: string | Date,
   newDate: boolean = false
) {
   if (!dateString) return '';
   const dateObj = new Date(dateString);

   return newDate
      ? new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000)
           .toISOString()
           .split('T')[0]
      : dateObj.toISOString().split('T')[0];
}

export function getNthDay(date: number) {
   if (date > 3 && date < 21) return 'th';
   switch (date % 10) {
      case 1:
         return 'st';
      case 2:
         return 'nd';
      case 3:
         return 'rd';
      default:
         return 'th';
   }
}
